import { OnBoardingContextProvider } from "../contexts/OnBoardingContext";
import { isNil } from "lodash";
import AppSoundless from "@layouts/AppSoundless";
import { OnboardingLayout } from "@layouts/OnboardingLayout";
import useUser from "@hooks/user/useUser";
import { useRouter } from "next/router";
import { Suspense, useEffect } from "react";
import UserModel from "@/Core/Models/User/UserModel";
import SuspenseFallbackDefault from "@components/SuspenseFallback/Default";
import { System } from "@design-system/index";

const HomeData = () => {
    const { user, isLoading } = useUser<UserModel>({
        params: {
            expand: ["total_projects"],
        },
        suspense: false,
        options: {
            withModel: true,
        },
    });

    const router = useRouter();
    useEffect(() => {
        if (!user) {
            return;
        }
        if (user.total_projects > 0) {
            router.push("/projects");
        }
    }, [user]);

    if (isNil(user) || isLoading || (!isNil(user) && user.total_projects > 0)) {
        return <System.SuspenseFallback.Security />;
    }

    return (
        <OnBoardingContextProvider>
            <OnboardingLayout />
        </OnBoardingContextProvider>
    );
};

const Home = () => {
    return (
        <AppSoundless>
            <Suspense fallback={<SuspenseFallbackDefault />}>
                <HomeData />
            </Suspense>
        </AppSoundless>
    );
};

export default Home;
