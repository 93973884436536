import qs from "query-string";
import { getFetchPrivateHeaders } from "@/Core/Fetcher/getFetchPrivateHeaders";
import { getPrivateApiMyWorkspaceMembersPath } from "../api/routes-private";

export const removeWorkspaceMembers = async (workspaceMemberIds: string[]) => {
    const url = qs.stringifyUrl({
        url: getPrivateApiMyWorkspaceMembersPath(),
        query: {
            workspaceMemberIds: workspaceMemberIds,
        },
    });

    const headers = await getFetchPrivateHeaders();

    const response = await fetch(url, {
        method: "DELETE",
        headers: headers,
        body: "",
    });

    return await response.json();
};
