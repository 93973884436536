import { getFetchPrivateHeaders } from "@/Core/Fetcher/getFetchPrivateHeaders";
import { getPrivateApiMyWorkspaceSingleMemberPath } from "../api/routes-private";

export const updateWorkspaceMember = async (
    workspaceMemberId: string,
    defaultRoleId: string,
    autoAssign: boolean,
    status: string
) => {
    const headers = await getFetchPrivateHeaders();

    const response = await fetch(
        getPrivateApiMyWorkspaceSingleMemberPath(workspaceMemberId),
        {
            method: "PUT",
            headers: headers,
            body: JSON.stringify({
                defaultRoleId: defaultRoleId,
                autoAssign: autoAssign,
                status: status,
            }),
        }
    );

    return await response.json();
};
