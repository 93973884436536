import { getFetchPrivateHeaders } from "@/Core/Fetcher/getFetchPrivateHeaders";
import { getPrivateApiMyWorkspaceAssignProjectsPath } from "../api/routes-private";

export const assignProjects = async (values: {
    workspaceMemberIds: string[];
    projectIds: number[];
}) => {
    const headers = await getFetchPrivateHeaders();

    const response = await fetch(getPrivateApiMyWorkspaceAssignProjectsPath(), {
        method: "POST",
        headers: headers,
        body: JSON.stringify(values),
    });

    return await response.json();
};
