import UserModel from "@/Core/Models/User/UserModel";
import { useWorkspaces } from "@components/Workspace/hooks/useWorkspaces";
import { Transition } from "@headlessui/react";
import useUser from "@hooks/user/useUser";
import {
    AuthLayoutWrapper,
    AuthMarketingSection,
    AuthSectionDescription,
    AuthSectionHeader,
    AuthSectionLayout,
    AuthSectionTitle,
    AuthSectionWrapper,
} from "@layouts/index";
import { WorkspaceMenu } from "@layouts/Workspace/WorkspaceMenu";
import { Suspense, useState } from "react";
import { ConnectFirstWebsite, SetUpAccountForm } from "./components";
import { useOnboardingStore } from "./onboardingStore";

export const OnboardingLayout = () => {
    const { user } = useUser({ options: { withModel: true } });
    // Redirect to the projects page if the user is invited to a workspace with projects
    const { data: workspaces, isLoading: isLoadingWorkspaces } =
        useWorkspaces();

    const haveCompletedStepOne = useOnboardingStore(
        (state) => state.haveCompletedStepOne
    );
    const setHaveCompletedStepOne = useOnboardingStore(
        (state) => state.setHaveCompletedStepOne
    );

    const [step, setStep] = useState<1 | 2>(
        haveCompletedStepOne.includes((user as UserModel).email) ? 2 : 1
    );

    return (
        <>
            <Transition
                as={AuthLayoutWrapper}
                show={step === 1}
                enter="transition ease-in duration-300"
                leave="transition ease-out duration-300 transform"
                enterFrom="opacity-0 -translate-x-full"
                enterTo="opacity-100 translate-x-0"
                leaveFrom="opacity-100 translate-x-0 h-0"
                leaveTo="opacity-0 -translate-x-full h-0"
            >
                <AuthMarketingSection className="basis-1/2">
                    <div className="flex flex-col gap-4 items-start max-w-[480px]">
                        {!isLoadingWorkspaces && workspaces.length > 1 && (
                            <Suspense fallback={null}>
                                <WorkspaceMenu />
                            </Suspense>
                        )}

                        <p className="font-semibold text-primary-base">
                            Customize your account to get started
                        </p>

                        <h1 className="text-title-h1">
                            Welcome to{" "}
                            <span className="text-primary-base">
                                WP Umbrella
                            </span>
                        </h1>

                        <p className="text-body-md text-main-grey-500">
                            Let us know who you are and so we can offer you a
                            better experience.
                        </p>
                    </div>
                </AuthMarketingSection>

                <AuthSectionWrapper className="basis-1/2">
                    <AuthSectionLayout className="max-w-3xl">
                        <AuthSectionHeader>
                            <AuthSectionTitle>
                                Set up your account
                            </AuthSectionTitle>

                            <AuthSectionDescription>
                                Let us know who you are and so we can offer you
                                the best experience.
                            </AuthSectionDescription>
                        </AuthSectionHeader>

                        <SetUpAccountForm
                            onSubmitSuccessful={() => {
                                setStep(2);
                                setHaveCompletedStepOne(
                                    (user as UserModel).email
                                );
                            }}
                        />
                    </AuthSectionLayout>
                </AuthSectionWrapper>
            </Transition>

            <Transition
                as={AuthLayoutWrapper}
                show={step === 2}
                enter="transition ease-in duration-300 translate-y-0"
                leave="transition ease-out duration-300 transform translate-y-0"
                enterFrom="opacity-0 translate-x-full"
                enterTo="opacity-100 translate-x-0"
                leaveFrom="opacity-100 translate-x-0"
                leaveTo="opacity-0 translate-x-full"
            >
                <AuthMarketingSection className="basis-1/2">
                    <div className="flex flex-col gap-4 items-start max-w-md">
                        <p className="font-semibold text-primary-base text-base">
                            CONNECT YOUR FIRST WEBSITE
                        </p>

                        <h1 className="text-title-h1">
                            How to{" "}
                            <span className="text-primary-base">
                                Add my Website
                            </span>{" "}
                            to the Dashboard?
                        </h1>

                        <p className="text-body-md text-main-grey-500">
                            You need to install WP Umbrella's plugin on your
                            website and connect your API key to access the
                            dashboard.
                        </p>
                    </div>
                </AuthMarketingSection>

                <div className="basis-1/2">
                    <ConnectFirstWebsite />
                </div>
            </Transition>
        </>
    );
};
