import { getFetchPrivateHeaders } from "@/Core/Fetcher/getFetchPrivateHeaders";
import { getPrivateApiMyWorkspaceResendInvitePath } from "../api/routes-private";

export const resendInvite = async (workspaceMemberId: string) => {
    const headers = await getFetchPrivateHeaders();

    const response = await fetch(
        getPrivateApiMyWorkspaceResendInvitePath(workspaceMemberId),
        {
            method: "POST",
            headers: headers,
        }
    );

    return await response.json();
};
