import fetch from "node-fetch";
import { first, isNil } from "lodash";
import { getFetchPrivateHeaders } from "@/Core/Fetcher/getFetchPrivateHeaders";

export const fetcherPrivateApiUmbrella = () =>
    async function (...args: any) {
        const defaultHeaders = await getFetchPrivateHeaders();

        const headers = {
            ...defaultHeaders,
        };

        const url = first(args);

        try {
            const res = await fetch(url, {
                method: "GET",
                headers: headers,
            });
            const total = res.headers.get("X-Total");

            const data = await res.json();
            if (isNil(total)) {
                return data;
            }

            return {
                total: Number(total),
                items: data,
            };
        } catch (error) {
            return null;
        }
    };

export default fetcherPrivateApiUmbrella;
