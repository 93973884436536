import { Suspense } from "react";
import Config from "@environments/data";

import SuspenseFallbackSecurity from "../components/SuspenseFallback/Security";
import { InitialDataContextProvider } from "../contexts/InitialDataContext";
import isServer from "../helpers/isServer";
import useTokenInit from "../hooks/user/useTokenInit";
import Beacon from "./Beacon";
import { useSession } from "next-auth/react";
import SecurityApplication from "@components/ApplicationSecurity/Application";
import { System } from "@design-system/index";

const AppSoundless = ({ children, initialData = {} }) => {
    const { status } = useSession();
    useTokenInit({
        redirectTo: Config.pages.auth.login,
        redirectIfFound: false,
    });

    if (status === "loading") {
        return <System.SuspenseFallback.Security />;
    }

    return (
        <>
            <SecurityApplication>
                <InitialDataContextProvider values={initialData}>
                    {!isServer() && (
                        <Suspense
                            fallback={
                                <>
                                    <SuspenseFallbackSecurity />
                                </>
                            }
                        >
                            {children}
                            <Beacon />
                        </Suspense>
                    )}
                </InitialDataContextProvider>
            </SecurityApplication>
        </>
    );
};

export default AppSoundless;
