import { getSession, signOut } from "next-auth/react";
import { AuthSession } from "../Models/AuthSession";
import { get, isNil } from "lodash";

let _bearerToken: string | null = null;
let _connectedUserId: string | number | null = null;

export const getFetchPrivateHeaders = async () => {
    let headers = {
        "Content-Type": "application/json",
    } as {
        "Content-Type": string;
        Authorization?: string;
        "X-Connected-User-Id"?: string;
    };

    if (!isNil(_bearerToken)) {
        headers = {
            ...headers,
            Authorization: `Bearer ${_bearerToken}`,
        };
    }

    if (!isNil(_connectedUserId)) {
        headers = {
            ...headers,
            "X-Connected-User-Id": `${_connectedUserId}`,
        };
    }

    if (!isNil(_bearerToken)) {
        return headers;
    }

    const session = (await getSession()) as AuthSession;

    if (!session) {
        return {
            "Content-Type": "application/json",
        };
    }

    _bearerToken = get(session, "user.private_token", null);
    if (!isNil(get(session, "user.mainUser", null))) {
        _connectedUserId = get(session, "user.user_id", null);
        headers = {
            ...headers,
            "X-Connected-User-Id": `${_connectedUserId}`,
        };
    }

    if (isNil(_bearerToken)) {
        await signOut({
            redirect: false,
        });

        window.location.href = "/auth/signin";
        return;
    }

    headers = {
        ...headers,
        Authorization: `Bearer ${_bearerToken}`,
    };

    /**
     * Do not delete this code
     * @legacy
     */
    // const privateCookie = get(session, "user.privateCookie", null);

    // // We use privateCookie if we don't have a bearer token and we have a privateCookie
    // if (
    //     !isNil(privateCookie) &&
    //     !isEmpty(privateCookie) &&
    //     isNil(_bearerToken)
    // ) {
    //     _privateCookie = privateCookie;
    //     return {
    //         "Content-Type": "application/json",
    //     };
    // }

    return headers;
};
