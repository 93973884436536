import { OrganizationType } from "@prisma/client";

declare const _cio: {
    identify: Function;
    page: Function;
    track: Function;
    reset: Function;
    on: Function;
    off: Function;
};

export class CustomerIONotInScopeError extends Error {
    constructor(...params) {
        super(...params);
    }
}

type User = Partial<{
    first_name: string;
    last_name: string;
    organization_type: OrganizationType;
}>;

export const getCustomerIoBrowserClient = () => {
    if (typeof _cio === "undefined") {
        return {
            identify: () => {},
        };
    }

    return {
        identify: (email: string, data: User) => {
            return _cio.identify({ ...data, email, id: email });
        },
    };
};
