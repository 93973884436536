import { Logo } from "@design-system/index";
import { cn } from "@helpers/utils";
import Link from "next/link";
import { PropsWithChildren } from "react";

interface AuthMarketingSectionProps extends PropsWithChildren {
    className?: string;
}

export const AuthMarketingSection = ({
    children,
    className,
}: AuthMarketingSectionProps) => {
    return (
        <div
            className={cn(
                "bg-auth-layout-gradient relative pt-6 pr-20 pb-36 pl-36 flex flex-col items-start gap-16 overflow-hidden",
                className
            )}
        >
            <img
                src="/images/auth/umbrella-auth-bg.png"
                className="absolute -left-[16%] top-1/4 pointer-events-none"
            />
            <Link href={"/"}>
                <Logo withText className="h-12 shrink-0" />
            </Link>
            <div className="flex flex-col gap-20 my-auto">{children}</div>
        </div>
    );
};
