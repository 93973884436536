import { PropsWithChildren } from "react";

export const ConnectFirstWebsiteStep = ({ children }: PropsWithChildren) => {
    return <div className="space-y-4">{children}</div>;
};
export const ConnectFirstWebsiteStepNumber = ({
    children,
}: PropsWithChildren) => {
    return (
        <span className="px-2 py-0.5 bg-primary-lightest rounded-xs text-primary-base text-title-h4">
            {children}
        </span>
    );
};
export const ConnectFirstWebsiteStepTitle = ({
    children,
}: PropsWithChildren) => {
    return (
        <h3 className="text-title-h3 text-primary-darkest flex gap-2 items-center">
            {children}
        </h3>
    );
};
export const ConnectFirstWebsiteStepDescription = ({
    children,
}: PropsWithChildren) => {
    return (
        <p className="text-body-md text-primary-darkest flex items-center whitespace-pre-wrap flex-wrap gap-1">
            {children}
        </p>
    );
};
