import qs from "query-string";
import Config from "@environments/data";

/**
 * @returns https://private-api.wp-umbrella.com/me/workspace
 */
export const getPrivateApiMyWorkspacePath = () => {
    return `${Config.private_api_url}/me/workspace`;
};

/**
 * @returns https://private-api.wp-umbrella.com/me/workspace/api-key
 */
export const getPrivateApiMyWorkspaceApiKey = () => {
    return `${getPrivateApiMyWorkspacePath()}/api-key`;
};

/**
 * @returns https://private-api.wp-umbrella.com/me/workspace/members
 */
export const getPrivateApiMyWorkspaceMembersPath = () => {
    return `${getPrivateApiMyWorkspacePath()}/members`;
};

/**
 * @returns https://private-api.wp-umbrella.com/me/workspace/members/{workspaceMemberId}
 */
export const getPrivateApiMyWorkspaceSingleMemberPath = (
    workspaceMemberId: string
) => {
    return `${getPrivateApiMyWorkspaceMembersPath()}/${workspaceMemberId}`;
};

/**
 * @returns https://private-api.wp-umbrella.com/me/workspace/members/{workspaceMemberId}/resend-invite
 */
export const getPrivateApiMyWorkspaceResendInvitePath = (
    workspaceMemberId: string
) => {
    return `${getPrivateApiMyWorkspaceSingleMemberPath(
        workspaceMemberId
    )}/resend-invite`;
};

/**
 * @returns https://private-api.wp-umbrella.com/me/workspace/members/assign-projects
 */
export const getPrivateApiMyWorkspaceAssignProjectsPath = () => {
    return `${getPrivateApiMyWorkspaceMembersPath()}/assign-projects`;
};

/**
 * @returns https://private-api.wp-umbrella.com/me/workspace/members/unassign-projects
 */
export const getPrivateApiMyWorkspaceUnassignProjectsPath = () => {
    return `${getPrivateApiMyWorkspaceMembersPath()}/unassign-projects`;
};

/**
 * @returns https://private-api.wp-umbrella.com/me/workspace/members/projects
 */
export const getPrivateApiMyWorkspaceProjectsPath = (
    workspaceMemberIds: string[]
) => {
    return qs.stringifyUrl({
        url: `${getPrivateApiMyWorkspaceMembersPath()}/projects`,
        query: {
            workspaceMemberIds: workspaceMemberIds,
        },
    });
};

/**
 * @returns https://private-api.wp-umbrella.com/workspaces
 */
export const getPrivateApiWorkspacesPath = () => {
    return `${Config.private_api_url}/workspaces`;
};

/**
 * @returns https://private-api.wp-umbrella.com/anonymous/invite
 */
const getPrivateApiInvitePath = () => {
    return `${Config.private_api_url}/anonymous/invite`;
};

/**
 * @returns https://private-api.wp-umbrella.com/anonymous/invite/verify
 */
export const getPrivateApiInviteVerifyPath = () => {
    return `${getPrivateApiInvitePath()}/verify`;
};

/**
 * @returns https://private-api.wp-umbrella.com/anonymous/invite/accept
 */
export const getPrivateApiInviteAcceptPath = () => {
    return `${getPrivateApiInvitePath()}/accept`;
};
