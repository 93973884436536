import { useEffect, useState } from "react";
import { getCsrfToken } from "next-auth/react";

export function useCsrfToken() {
    const [csrfToken, setCsrfToken] = useState<string>();

    useEffect(() => {
        const getCsrf = async () => {
            setCsrfToken(await getCsrfToken());
        };
        getCsrf();
    }, []);

    return csrfToken;
}
