import { updateWorkspace } from "./updateWorkspace";
import { removeWorkspaceMembers } from "./removeWorkspaceMembers";
import { updateWorkspaceMember } from "./updateWorkspaceMember";
import { addMembers } from "./addMembers";
import { assignProjects } from "./assignProjects";
import { unassignProjects } from "./unassignProjects";
import { verifyInvite } from "./verifyInvite";
import { acceptInvite } from "./acceptInvite";
import { resendInvite } from "./resendInvite";

export const FetcherWorkspace = {
    updateWorkspace: updateWorkspace,
    removeWorkspaceMembers: removeWorkspaceMembers,
    updateWorkspaceMember: updateWorkspaceMember,
    addMembers: addMembers,
    assignProjects: assignProjects,
    unassignProjects: unassignProjects,
    verifyInvite: verifyInvite,
    acceptInvite: acceptInvite,
    resendInvite: resendInvite,
};
