import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogTrigger,
    Link,
    System,
    Tag,
    TextFieldCopyToClipboard,
} from "@design-system/index";
import useNotifications from "@hooks/notifications/useNotifications";
import useApiKey from "@hooks/user/useApiKey";
import {
    ConnectFirstWebsiteStep,
    ConnectFirstWebsiteStepDescription,
    ConnectFirstWebsiteStepNumber,
    ConnectFirstWebsiteStepTitle,
} from "./ConnectFirstWebsiteStep";
import { useState, useEffect } from "react";

export const ConnectFirstWebsite = () => {
    const apiKey = useApiKey();
    const { handleNotifySuccess } = useNotifications();

    const [copied, setCopied] = useState(false);
    useEffect(() => {
        if (!copied) {
            return;
        }

        const timeout = setTimeout(() => {
            setCopied(false);
        }, 4000);

        return () => {
            clearTimeout(timeout);
        };
    }, [copied]);

    return (
        <div className="p-14 flex flex-col items-center w-full">
            <div className="space-y-8 max-w-[608px] w-full">
                <ConnectFirstWebsiteStep>
                    <ConnectFirstWebsiteStepTitle>
                        <ConnectFirstWebsiteStepNumber>
                            1
                        </ConnectFirstWebsiteStepNumber>
                        Copy your API key
                    </ConnectFirstWebsiteStepTitle>

                    <TextFieldCopyToClipboard className="min-w-full">
                        {apiKey}
                    </TextFieldCopyToClipboard>
                </ConnectFirstWebsiteStep>

                <ConnectFirstWebsiteStep>
                    <ConnectFirstWebsiteStepTitle>
                        <ConnectFirstWebsiteStepNumber>
                            2
                        </ConnectFirstWebsiteStepNumber>
                        Install the plugin
                    </ConnectFirstWebsiteStepTitle>

                    <ConnectFirstWebsiteStepDescription>
                        Go to your
                        <Tag>
                            <System.Svg.WordPress
                                size={16}
                                className="text-black"
                            />
                            Wordpress Admin
                        </Tag>
                        and install
                        <Tag>
                            <System.Svg.WPUmbrella size={16} />
                            WP Umbrella
                        </Tag>
                        from the
                        <Tag>
                            <System.Svg.Plugin size={16} /> Plugin directory
                        </Tag>
                    </ConnectFirstWebsiteStepDescription>

                    <ConnectFirstWebsiteStepDescription>
                        Don't forget to activate the plugin after installation.
                    </ConnectFirstWebsiteStepDescription>

                    <Dialog>
                        <DialogTrigger className="hover:scale-105 transition-transform">
                            <img
                                src="/images/connect-first-website/install-plugin-screenshot.png"
                                alt="Install plugin screenshot"
                                className="max-h-[150px] rounded"
                            />
                        </DialogTrigger>
                        <DialogContent className="max-w-7xl">
                            <DialogTitle> Install the plugin</DialogTitle>

                            <img
                                src="/images/connect-first-website/install-plugin-screenshot.png"
                                alt="Install plugin screenshot"
                                className="rounded"
                            />
                        </DialogContent>
                    </Dialog>
                </ConnectFirstWebsiteStep>

                <ConnectFirstWebsiteStep>
                    <ConnectFirstWebsiteStepTitle>
                        <ConnectFirstWebsiteStepNumber>
                            3
                        </ConnectFirstWebsiteStepNumber>
                        Connect the plugin
                    </ConnectFirstWebsiteStepTitle>

                    <ConnectFirstWebsiteStepDescription>
                        Go to
                        <Tag>
                            <System.Svg.Settings size={16} />
                            Settings
                        </Tag>
                        in the left menu bar, click on
                        <Tag>
                            <System.Svg.WPUmbrella size={16} /> WP Umbrella
                        </Tag>
                        and paste your
                        <Tag
                            className="cursor-pointer"
                            onClick={() => {
                                setCopied(true);
                                navigator.clipboard.writeText(apiKey);
                                handleNotifySuccess({
                                    title: "API key copied to clipboard",
                                });
                            }}
                        >
                            {!copied && <System.Svg.CopyPaste size={16} />}
                            {copied && (
                                <span
                                    className={
                                        "bg-main-white rounded-full flex items-center justify-center p-0.5 border border-success-base"
                                    }
                                >
                                    <System.Svg.Check
                                        size={12}
                                        className="text-success-base"
                                    />
                                </span>
                            )}
                            API key
                        </Tag>
                    </ConnectFirstWebsiteStepDescription>

                    <Dialog>
                        <DialogTrigger className="hover:scale-105 transition-transform">
                            <img
                                src="/images/connect-first-website/connect-plugin-screenshot.png"
                                alt="Connect plugin screenshot"
                                className="max-h-[150px] rounded"
                            />
                        </DialogTrigger>
                        <DialogContent className="max-w-7xl">
                            <DialogTitle>Connect the plugin</DialogTitle>

                            <img
                                src="/images/connect-first-website/connect-plugin-screenshot.png"
                                alt="Connect plugin screenshot"
                                className="rounded"
                            />
                        </DialogContent>
                    </Dialog>
                </ConnectFirstWebsiteStep>

                <div className="border-l-[6px] border-l-primary-lighter rounded-lg flex px-6 py-4 w-full gap-4 bg-primary-lightest">
                    <System.Loader className="shrink-0 mt-0.5" />

                    <div className="space-y-1.5">
                        <span className="font-semibold text-body-base inline-flex gap-2">
                            Connect a website to access the dashboard
                        </span>

                        <p className="text-body-base text-primary-darkest">
                            You must connect one site to access to your
                            dashboard. You'll be redirected to the dashboard
                            automatically when a site is connected.
                        </p>
                        <p className="text-body-base text-primary-darkest">
                            Need help?{" "}
                            <Link
                                href="https://wp-umbrella.com/support/"
                                target="_blank"
                            >
                                Contact our support team!
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};
