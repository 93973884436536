const SuspenseFallbackDefault = () => {
    return (
        <div className="animate-pulse flex space-x-4">
            <div className="flex-1 py-1">
                <div className="h-2 bg-gray-400 rounded"></div>
            </div>
        </div>
    );
};

export default SuspenseFallbackDefault;
