import { forwardRef, PropsWithChildren } from "react";

const AuthLayoutWrapper = forwardRef<HTMLDivElement, PropsWithChildren<{}>>(
    ({ children }, ref) => {
        return (
            <div
                ref={ref}
                className="min-w-full min-h-full h-fit flex items-stretch"
            >
                {children}
            </div>
        );
    }
);
AuthLayoutWrapper.displayName = "AuthLayoutWrapper";

export { AuthLayoutWrapper };
