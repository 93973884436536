import { OrganizationType } from "@prisma/client";
import { get } from "lodash";
import { createContext, useState } from "react";

export enum TypeInstall {
    MANUAL,
    AUTOMATIC,
}

type OnBoardingType = {
    values: {
        step: number;
        data: {
            url?: string;
            type_install?: TypeInstall;
            projectId?: number;
            number_projects?: string;
            type_of_organization?: OrganizationType;
            firstname: string;
            workspace_name?: string;
        };
        websites: Array<{ id: number; is_connected: boolean }>;
    };
    actions: {
        setStep: Function;
        setData: Function;
        setWebsites: Function;
    };
};

export const OnBoardingContext = createContext<OnBoardingType>({
    values: {
        step: 1,
        data: {
            number_projects: "1_5",
            url: "",
            type_install: null,
            projectId: null,
            type_of_organization: null,
            firstname: "",
            workspace_name: "",
        },
        websites: [],
    },
    actions: {
        setStep: () => {},
        setData: () => {},
        setWebsites: () => {},
    },
});

export const OnBoardingContextProvider = ({ values, children }: any) => {
    const [step, setStep] = useState(get(values, "step", 1));
    const [data, setData] = useState<OnBoardingType["values"]["data"]>(
        get(values, "data", {
            number_projects: "1_5",
            url: "",
            type_install: TypeInstall.MANUAL,
            projectId: null,
            type_of_organization: null,
            firstname: "",
        })
    );

    const [websites, setWebsites] = useState([]);

    return (
        <OnBoardingContext.Provider
            value={{
                values: {
                    step,
                    data,
                    websites,
                },
                actions: {
                    setStep,
                    setData,
                    setWebsites,
                },
            }}
        >
            {children}
        </OnBoardingContext.Provider>
    );
};
