import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

interface OnboardingStoreState {
    haveCompletedStepOne: string[];
    setHaveCompletedStepOne: (email: string) => void;
}

export const useOnboardingStore = create<OnboardingStoreState>()(
    devtools(
        persist(
            (set) => ({
                haveCompletedStepOne: [],
                setHaveCompletedStepOne: (email) =>
                    set((state) => ({
                        haveCompletedStepOne: [
                            ...state.haveCompletedStepOne,
                            email,
                        ],
                    })),
            }),
            {
                name: "onboarding-store",
            }
        )
    )
);
