import { getPrivateApiInviteAcceptPath } from "../api/routes-private";

export const acceptInvite = async (values: {
    token: string;
    password?: string;
}) => {
    const response = await fetch(getPrivateApiInviteAcceptPath(), {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
    });

    return await response.json();
};
