import { getFetchPrivateHeaders } from "@/Core/Fetcher/getFetchPrivateHeaders";
import { getPrivateApiMyWorkspaceUnassignProjectsPath } from "../api/routes-private";

export const unassignProjects = async (values: {
    workspaceMemberIds: string[];
    projectIds: number[];
}) => {
    const headers = await getFetchPrivateHeaders();

    const response = await fetch(
        getPrivateApiMyWorkspaceUnassignProjectsPath(),
        {
            method: "POST",
            headers: headers,
            body: JSON.stringify(values),
        }
    );

    return await response.json();
};
