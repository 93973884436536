import qs from "query-string";
import { getPrivateApiInviteVerifyPath } from "../api/routes-private";

export const verifyInvite = async (token: string) => {
    const url = qs.stringifyUrl({
        url: getPrivateApiInviteVerifyPath(),
        query: {
            token: token,
        },
    });

    const response = await fetch(url, {
        method: "GET",
    });

    return await response.json();
};
