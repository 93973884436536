import { Separator } from "@design-system/index";
import { cn } from "@helpers/utils";
import { PropsWithChildren } from "react";

interface AuthSectionProps extends PropsWithChildren {
    className?: string;
}

export const AuthSectionWrapper = ({
    className,
    children,
}: AuthSectionProps) => {
    return (
        <div
            className={cn(
                "bg-main-white flex items-center justify-center",
                className
            )}
        >
            {children}
        </div>
    );
};

interface AuthSectionLayoutProps extends PropsWithChildren {
    className?: string;
}

export const AuthSectionLayout = ({
    className,
    children,
}: AuthSectionLayoutProps) => {
    return (
        <div
            className={cn(
                "flex flex-col justify-center gap-10 px-20 py-14 max-w-xl",
                className
            )}
        >
            {children}
        </div>
    );
};

export const AuthSectionHeader = ({ children }: PropsWithChildren) => {
    return <div className="flex flex-col gap-2">{children}</div>;
};

export const AuthSectionTitle = ({ children }: PropsWithChildren) => {
    return <h2 className="text-title-h2 text-primary-darkest">{children}</h2>;
};

export const AuthSectionDescription = ({ children }: PropsWithChildren) => {
    return <p className="text-body-base text-main-grey-500">{children}</p>;
};

export const AuthSectionContent = ({ children }: PropsWithChildren) => {
    return <div className="space-y-4">{children}</div>;
};

export const AuthSectionProviderSeparator = ({
    children,
}: PropsWithChildren) => {
    return (
        <div className="flex gap-6 items-center">
            <Separator className="shrink" />
            <span className="uppercase text-main-grey-500 text-body-sm font-medium shrink-0">
                {children}
            </span>
            <Separator className="shrink" />
        </div>
    );
};

export const AuthSectionFooter = ({ children }: PropsWithChildren) => {
    return (
        <div className="flex flex-col items-center justify-center gap-4">
            {children}
        </div>
    );
};

export const AuthSectionAlternative = ({ children }: PropsWithChildren) => {
    return (
        <div className="text-body-base font-semibold text-main-grey-600">
            {children}
        </div>
    );
};
