import UserModel from "@/Core/Models/User/UserModel";
import FetchApiMe from "@/FetchApi/Me";
import { getCustomerIoBrowserClient } from "@/Services/Email/clients/customerIoBrowserClient";
import { FetcherWorkspace } from "@/Workspace/Fetcher";
import { useWorkspaces } from "@components/Workspace/hooks/useWorkspaces";
import {
    Button,
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
    Input,
    RadioGroup,
    RadioGroupItemCard,
    RadioGroupItemDescription,
    RadioGroupItemLabel,
} from "@design-system/index";
import { zodResolver } from "@hookform/resolvers/zod";
import useUser from "@hooks/user/useUser";
import { OrganizationType } from "@prisma/client";
import { get } from "lodash";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

const formSchema = z.object({
    firstName: z.string().min(1, "First name is required"),
    workspaceName: z.string().min(1, "Workspace name is required"),
    howDidYouHearAboutUs: z.string().min(1, "This field is required"),
    organizationType: z.enum([
        OrganizationType.AGENCY,
        OrganizationType.FREELANCE,
    ]),
});

interface SetUpAccountFormProps {
    onSubmitSuccessful: () => void;
}

export const SetUpAccountForm = ({
    onSubmitSuccessful,
}: SetUpAccountFormProps) => {
    const { user } = useUser({ options: { withModel: true } });

    const { data: workspaces } = useWorkspaces();
    const personalWorkspaceNameOriginal = useMemo(
        () =>
            workspaces.find((workspace) => {
                return workspace.user_id === (user as UserModel).id;
            })?.name ?? "",
        [workspaces, user]
    );

    const firstNameOriginal = get(user, "firstname", "");
    const organizationTypeOriginal = get(user, "organization_type", "");

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: useMemo(() => {
            return {
                firstName: firstNameOriginal,
                workspaceName: personalWorkspaceNameOriginal,
                howDidYouHearAboutUs: "",
                organizationType: organizationTypeOriginal,
            };
        }, [firstNameOriginal, organizationTypeOriginal]),
    });

    const onSubmit = async ({
        firstName,
        organizationType,
        workspaceName,
        howDidYouHearAboutUs,
    }: z.infer<typeof formSchema>) => {
        await FetchApiMe.updatePersonalInformation({
            email: get(user, "email"),
            firstname: firstName,
            organization_type: organizationType,
            howDidYouHearAboutUs: howDidYouHearAboutUs,
        });

        try {
            await FetcherWorkspace.updateWorkspace({ name: workspaceName });
        } catch (error) {
            console.error(error);
        }

        try {
            getCustomerIoBrowserClient().identify(get(user, "email"), {
                first_name: firstName,
                organization_type: organizationType,
            });
        } catch (error) {
            console.error(error);
        }

        onSubmitSuccessful();
    };

    return (
        <Form {...form}>
            <form
                className="flex flex-col gap-10 items-center"
                onSubmit={form.handleSubmit(onSubmit)}
            >
                <div className="space-y-6">
                    <FormField
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>What is your first name?</FormLabel>
                                <FormControl>
                                    <Input
                                        placeholder="First Name"
                                        {...field}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                        name={"firstName"}
                        control={form.control}
                    />

                    <FormField
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>
                                    How do you want to name your workspace?
                                </FormLabel>
                                <FormControl>
                                    <Input
                                        placeholder="Workspace Name"
                                        {...field}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                        name={"workspaceName"}
                        control={form.control}
                    />

                    <FormField
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>
                                    How did you hear about us?
                                </FormLabel>

                                <FormControl>
                                    <Input
                                        placeholder="Who told you we're cool and where?"
                                        {...field}
                                    />
                                </FormControl>

                                <FormMessage />
                            </FormItem>
                        )}
                        name={"howDidYouHearAboutUs"}
                        control={form.control}
                    />

                    <FormField
                        control={form.control}
                        name="organizationType"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>What describes you best?</FormLabel>
                                <FormControl>
                                    <RadioGroup
                                        onValueChange={field.onChange}
                                        defaultValue={field.value}
                                        className="grid grid-cols-2 gap-3"
                                    >
                                        <RadioGroupItemCard
                                            value={OrganizationType.AGENCY}
                                        >
                                            <RadioGroupItemLabel>
                                                Agency
                                            </RadioGroupItemLabel>
                                            <RadioGroupItemDescription>
                                                I work with colleagues and I'd
                                                like to share access to my
                                                dashboard
                                            </RadioGroupItemDescription>
                                        </RadioGroupItemCard>

                                        <RadioGroupItemCard
                                            value={OrganizationType.FREELANCE}
                                        >
                                            <RadioGroupItemLabel>
                                                Freelance
                                            </RadioGroupItemLabel>
                                            <RadioGroupItemDescription>
                                                I work solo, and I don't need to
                                                share access to my account with
                                                others
                                            </RadioGroupItemDescription>
                                        </RadioGroupItemCard>
                                    </RadioGroup>
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>

                <Button
                    type="submit"
                    className="mx-auto"
                    disabled={form.formState.isSubmitting}
                >
                    Connect your first website
                </Button>
            </form>
        </Form>
    );
};
