import { getFetchPrivateHeaders } from "@/Core/Fetcher/getFetchPrivateHeaders";
import { getPrivateApiMyWorkspacePath } from "@/Workspace/api/routes-private";
import { isNil } from "lodash";

interface Props {
    name: string;
}

export const updateWorkspace = async (data: Props) => {
    const headers = await getFetchPrivateHeaders();
    delete headers["Content-Type"];

    const body = new FormData();

    Object.keys(data).forEach((key) => {
        if (!isNil(data[key])) {
            body.append(key, data[key]);
        }
    });

    const result = await fetch(getPrivateApiMyWorkspacePath(), {
        method: "PUT",
        headers: headers,
        body: body,
    });

    return await result.json();
};
