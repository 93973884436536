import { getFetchPrivateHeaders } from "@/Core/Fetcher/getFetchPrivateHeaders";
import { getPrivateApiMyWorkspaceMembersPath } from "../api/routes-private";

export const addMembers = async (values: {
    emails: string[];
    roleId: string;
    autoAssign: boolean;
}) => {
    const headers = await getFetchPrivateHeaders();

    const response = await fetch(getPrivateApiMyWorkspaceMembersPath(), {
        method: "POST",
        headers: headers,
        body: JSON.stringify(values),
    });

    const json = await response.json();

    if (json.code === "success") {
        return json.data || [];
    }

    throw new Error(json.message || "Failed to invite team members");
};
