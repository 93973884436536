import { isNil } from "lodash";
import useSWR from "swr";
import { morphism } from "morphism";
import { getPrivateApiWorkspacesPath } from "@/Workspace/api/routes-private";
import fetcherPrivateApiUmbrella from "@helpers/fetcherPrivateApiUmbrella";
import SingleWorkspaceModel, {
    SchemaSingleWorkspaceModel,
} from "@/Workspace/Model/SingleWorkspaceModel";
import { Workspace } from "@prisma/client";

interface Props {
    suspense?: boolean;
}

export const useWorkspaces = ({ suspense = true }: Props = {}) => {
    const path = getPrivateApiWorkspacesPath();

    const { data: response, ...rest } = useSWR<{
        code: string;
        data: (Workspace & {
            total_projects;
        })[];
    }>(path, {
        fetcher: fetcherPrivateApiUmbrella(),
        suspense: suspense,
    });

    const { code, data } = response || {};

    if (isNil(data) || code !== "success") {
        return {
            data: [],
            ...rest,
        };
    }

    return {
        data: morphism(SchemaSingleWorkspaceModel, data, SingleWorkspaceModel),
        ...rest,
    };
};
